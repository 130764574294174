<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Activity ({{totalQuoteCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex overflow-auto mb-2 items-center">
          <div class="pr-2  text-gray4 heading-5 ">Entity:</div>
          <div class="" v-for="(status, index) in entStatusList" :key="index"
          :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index)" :selected="status.selected" :btnText="status.entityName" :lastInlist="entStatusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex overflow-auto mb-2 items-center">
          <div class="pr-2 text-gray4 heading-5">Employee:</div>
          <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn v-if="activeUer !== status.userDetailId" class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
            <StatusBtn v-else class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="'Me'" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex flex-wrap items-center py-2">
          <!-- <div class="select_button text-sm text-gray3">
            <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
          </div> -->
          <div class="w-72 ">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Activity...`"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="activitiListing.length > 0">
            <div v-for="(data, index) in activitiListing" :key="index"  class="cust_card m-2 mx-1  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 " :class="(data.entitySlug === 'payment' || data.entitySlug === 'quotation' || data.entitySlug === 'invoice' || data.entitySlug === 'job' || data.entitySlug === 'visit'  || data.entitySlug === 'lead' || data.entitySlug === 'shift' || data.entitySlug === 'customer' || data.entitySlug === 'employee' || data.entitySlug === 'tax' || data.entitySlug === 'item' || data.entitySlug === 'payment_method' || data.entitySlug === 'expense' || data.entitySlug === 'request' || data.entitySlug === 'task') && ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) ? 'cursor-pointer' : ''">
              <div class="" v-if="!mobileView">
                <div class="flex justify-between">
                  <div class="flex" @click="openDetail(index)">
                    <div>
                      <!-- <i :class="data.icon"></i> -->
                      <span v-html="getIcons(data.entitySlug)"></span>
                      <!-- <span class="text-success heading-4" v-if="data.entitySlug === 'payment'">
                        <i class="fa-solid fa-dollar"></i>
                      </span>
                      <span class="text-error heading-4" v-if="data.entitySlug === 'invoice'">
                        <i class="fas fa-file-invoice"></i>
                      </span>
                      <span class="text-success heading-4" v-if="data.entitySlug === 'shift'">
                        <i class="fas fa-clock"></i>
                      </span>
                      <span class="text-primary heading-4" v-if="data.entitySlug === 'quotation'">
                        <i class="fab fa-quora"></i>
                      </span>
                      <span class="text-primary heading-4" v-if="data.entitySlug === 'visit'">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                      <span class="text-primary heading-4" v-if="data.entitySlug === 'job'">
                        <i class="fas fa-briefcase"></i>
                      </span>
                      <span class="text-indigo-500 heading-4" v-if="data.entitySlug === 'customer'">
                        <i class="fas fa-users"></i>
                      </span>
                      <span class="text-secondary heading-4" v-if="data.entitySlug === 'lead'">
                        <i class="fas fa-comment-dollar"></i>
                      </span> -->
                    </div>
                    <div class="pl-3">
                      <div class="text-gray4 heading-5">{{data.firstName}} {{data.lastName}}</div>
                      <div class="text-gray3 heading-5">{{data.activityTitle}}</div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && !data.isExpand">
                        <span v-if="data.activityDesc.length > 60">{{ data.activityDesc.substr(0, 22) }}...</span>
                        <span v-else>{{data.activityDesc}}</span>
                      </div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && data.isExpand">
                        <span>{{data.activityDesc}}</span>
                      </div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug === 'visit'">
                        <span>{{data.activityDesc}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-gray4 heading-5" :class="(data.entitySlug === 'payment' || data.entitySlug === 'invoice' || data.entitySlug === 'job' || data.entitySlug === 'quotation' || data.entitySlug === 'shift' || data.entitySlug === 'customer' || data.entitySlug === 'lead' || data.entitySlug === 'employee'  || data.entitySlug === 'visit' || data.entitySlug === 'tax' || data.entitySlug === 'item' || data.entitySlug === 'payment_method' || data.entitySlug === 'expense' || data.entitySlug === 'request') && ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) ? 'hover:text-primary' : ''" @click="redirectDetail(data)">{{data.addedDate | formatTime}}
                    <i class="fa-solid fa-chevron-right pl-2 text-primary"></i>
                  </div>
                </div>
              </div>
              <div v-if="mobileView" @click="openDetail(index)">
                <span class="text-gray4 heading-5 text-right flex justify-end" :class="(data.entitySlug === 'payment' || data.entitySlug === 'invoice' || data.entitySlug === 'job' || data.entitySlug === 'quotation' || data.entitySlug === 'shift' || data.entitySlug === 'customer' || data.entitySlug === 'lead' || data.entitySlug === 'employee'  || data.entitySlug === 'visit' || data.entitySlug === 'tax' || data.entitySlug === 'item' || data.entitySlug === 'payment_method' || data.entitySlug === 'expense' || data.entitySlug === 'request') && ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) ? 'hover:text-primary' : ''" @click="redirectDetail(data)">{{data.addedDate | formatTime}}
                  <i class="fa-solid fa-chevron-right pl-2 text-primary"></i>
                </span>
                <div class=" relative items-center flex">
                  <div class="flex items-center">
                    <span v-html="getIcons(data.entitySlug)"></span>
                    <div class="text-gray4 heading-5 pl-2">{{data.firstName}} {{data.lastName}}</div>
                  </div>
                </div>
                <div class="text-gray3 heading-5 mt-1">{{data.activityTitle}}</div>
                <div class="text-gray3 heading-5 whitespace-pre-line mt-1" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && !data.isExpand">
                  <span v-if="data.activityDesc.length > 60">{{ data.activityDesc.substr(0, 22) }}...</span>
                  <span v-else>{{data.activityDesc}}</span>
                </div>
                <div class="text-gray3 heading-5 whitespace-pre-line mt-1" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && data.isExpand">
                  <span>{{data.activityDesc}}</span>
                </div>
                <div class="text-gray3 heading-5 whitespace-pre-line mt-1" v-if="data.activityDesc !== '' && data.entitySlug === 'visit'">
                  <span>{{data.activityDesc}}</span>
                </div>
              </div>
            </div>
          <div>
          <Pagination
            :totalDataCount="totalQuoteCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
        </div>
        <div v-else>
            <NoRecords :alertMessage="` No records found, please check filters on this page in case you did not find required data.`" />
          </div>
      </div>
     </div>
   </div>
</template>
<script>
// import SimpleDropdown from '@/View/components/simpleDropdown.vue'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
// import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import {FilterPermissions} from '@/utils/Permissions.js'
export default {
  name: "activity",
  components: {
    // SimpleDropdown,
    NoRecords,
    StatusBtn,
    Pagination,
    SearchTextInput,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      temp: FilterPermissions(''),
      mobileView: false,
      titleObj: [
        {title: 'Quotation #', icon: '', sortName: 'quotationNumber'},
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Grand Total', icon: '', sortName: 'grandTotal'},
        {title: 'Expiry Date', icon: 'DESC', sortName: 'expiryDate'},
        {title: 'Status', icon: '', sortName: 'entityName'}
      ],
      entStatusList: [],
      empStatusList: [],
      activitiListing: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      activeUer: 0,
      totalQuoteCount: 0,
      items: [20, 25, 30, 50, 100],
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        sortBy: 'addedDate',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
     this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getActivityList()
      }
      this.addLead = false
    });
    this.getEnitityList()
    this.getEmployeeList()
    this.getActivityList()
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    toggleActiveAndInactiveA (index) {
      let filter = []
      if (this.entStatusList[index].entityName.toLowerCase() === 'all') {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      } else {
        this.entStatusList[index].selected = !this.entStatusList[index].selected
        this.filterObject.entStatusList = []
        this.entStatusList.forEach((a, id) => {
          if (this.entStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.entStatusList.push(a.entityId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.entStatusList.push(a.entityId)
            }
          }
        })
        this.entStatusList[0].selected = false
      }
      if (filter.length === this.entStatusList.length - 1) {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      }
      let arry = this.entStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.entStatusList.length) {
        this.entStatusList[0].selected = true
        this.filterObject.entStatusList = []
        for (let index = 0; index < this.entStatusList.length; index++) {
          this.entStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getActivityList()
    },
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.empStatusList = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.empStatusList.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.empStatusList.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getActivityList()
    },
    getIcons (data) {
    if (data === 'payment') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-dollar"></i></span>`
      } else if (data === 'invoice') {
        return `<span class="text-error heading-4"><i class="fas fa-file-invoice"></i></span>`
      } else if (data === 'shift') {
        return `<span class="text-success heading-4"><i class="fas fa-clock"></i></span>`
      } else if (data === 'quotation') {
        return `<span class="text-primary heading-4"> <i class="fab fa-quora"></i></span>`
      } else if (data === 'visit') {
        return `<span class="text-primary heading-4"><i class="fas fa-calendar-alt"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-primary heading-4"><i class="fas fa-briefcase"></i></span>`
      } else if (data === 'customer') {
        return `<span class="text-indigo-500 heading-4"><i class="fas fa-users"></i></span>`
      } else if (data === 'lead') {
        return `<span class="text-secondary heading-4"><i class="fas fa-comment-dollar"></i></span>`
      } else if (data === 'employee') {
        return `<span class="text-primary heading-4"><i class="fas fa-user-tie"></i></span>`
      } else if (data === 'payment_method') {
        return `<span class="text-primary heading-4"><i class="far fa-money-bill-alt"></i></span>`
      } else if (data === 'tax') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-percent"></i></span>`
      } else if (data === 'item') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-box"></i></span>`
      } else if (data === 'expense') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-money-bill-transfer"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-code-pull-request"></i></span>`
      } else if (data === 'task') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-clipboard-list"></i></span>`
      } else if (data === 'check_list_item') {
        return `<span class="text-gray-500 heading-4"><i class="fa-solid fa-list-check"></i></span>`
      } else if (data === '') {
        return `<span class="text-gray4 heading-4"><i class="fa-regular fa-circle-dot"></i></span>`
      }
    },
    openDetail (index) {
      this.activitiListing[index].isExpand = !this.activitiListing[index].isExpand
    },
    redirectDetail (data) {
      if ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) {
        let value = 0
        if (data.entitySlug === 'visit') {
          value = data.entitySubDetailId
        } else if (data.entityDetailId === 0 && data.entitySubDetailId > 0) {
          value  = data.entitySubDetailId
        } else if (data.entityDetailId > 0) {
          value = data.entityDetailId
        }
        let url = ''
        if (data.entitySlug === 'payment') {
          url = this.$router.resolve({name: 'paymentDetails', params: {paymentId: value}})
        } else if (data.entitySlug === 'invoice') {
          url = this.$router.resolve({name: 'invoiceDetails', params: {invoiceId: value}})
        } else if (data.entitySlug === 'job') {
          url = this.$router.resolve({name: 'jobDetail', params: {jobId: value}})
        } else if (data.entitySlug === 'visit') {
          url = this.$router.resolve({name: 'visitDetail', params: {visitId: value}})
        } else if (data.entitySlug === 'quotation') {
          url = this.$router.resolve({name: 'quoteDetail', params: {quoteId: value}})
        } else if (data.entitySlug === 'customer') {
          url = this.$router.resolve({name: 'customerDetails', params: {customerId: value}})
        } else if (data.entitySlug === 'shift') {
          url = this.$router.resolve({name: 'shiftDetail', params: {shiftId: value}})
        } else if (data.entitySlug === 'lead') {
          url = this.$router.resolve({name: 'LeadDetail', params: {leadId: value}})
        } else if (data.entitySlug === 'employee') {
          url = this.$router.resolve({name: 'SettingEmployeesDetail', params: {empId: value}, query: {showDelele: false}})
        } else if (data.entitySlug === 'item') {
          url = this.$router.resolve({name: 'Items'})
        } else if (data.entitySlug === 'payment_method') {
          url = this.$router.resolve({name: 'SettingPaymentMethod'})
        } else if (data.entitySlug === 'tax') {
          url = this.$router.resolve({name: 'SettingTax'})
        } else if (data.entitySlug === 'expense') {
          url = this.$router.resolve({name: 'ExpenseDetail', params: {expId: value}})
        } else if (data.entitySlug === 'request') {
          url = this.$router.resolve({name: 'requestDetail', params: {requestId: value}})
        }
        if (data.entitySlug !== 'task' && data.entitySlug !== '') {
          window.open(url.href, '_blank')
        }
      }
    },
    addNewCust () {
      this.addLead= true
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getActivityList(true)
    },
    openLeadDetails (id) {
      this.$router.push({name: 'customerDetails', params: {customerId: id}})
    },
    searchCall (data) {
      this.searchForQuotation = data
      this.getQuotationDebounce()
    },
    getEnitityList () {
      MyJobApp.getEntityList(
        response => {
          let temp = response.Data !== null ? response.Data : [] 
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].canViewPermission = FilterPermissions(temp[index].entitySlug)
          }
          const newFirstElement = {
            entityId: 0,
            entityName: "All",
            entitySlug: "all",
            selected: true,
            canViewPermission: true
          }
          this.entStatusList = [newFirstElement].concat(temp)
          console.log('this.entStatusList', this.entStatusList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          const findex = temp.findIndex(x => x.userDetailId === this.activeUer)
          if (findex > -1) {
            let element = temp[findex];
            temp.splice(findex, 1);
            temp = [element, ...temp];
          }
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: true
          }
          this.empStatusList = [newFirstElement].concat(temp)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getActivityList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForQuotation === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      // this.filterObject.entStatusList = []
      // this.entStatusList.map((data) => {
      //   if (data.selected) {
      //     this.filterObject.entStatusList.push(data.entityId)
      //   }
      // })
      MyJobApp.getActivitys(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForQuotation,
        this.filterObject.entStatusList,
        this.filterObject.empStatusList,
        response => {
          this.totalQuoteCount = response.Data.count
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].isExpand = false
          }
          //   if (temp[index].entitySlug === 'payment') {
          //     temp[index].icon = 'fa-solid fa-dollar'
          //     temp[index].color = '#1AB426'
          //   } else if (temp[index].entitySlug === 'invoice') {
          //     temp[index].icon = 'fas fa-file-invoice'
          //     temp[index].color = '#DC2626'
          //   } else if (temp[index].entitySlug === 'shift') {
          //     temp[index].icon = 'fas fa-clock'
          //     temp[index].color = '#1AB426'
          //   } else if (temp[index].entitySlug === 'quotation') {
          //     temp[index].icon = 'fab fa-quora'
          //     temp[index].color = '#1295BA'
          //   } else if (temp[index].entitySlug === 'visit') {
          //     temp[index].icon = 'far fa-clipboard'
          //     temp[index].color = '#1295BA'
          //   }
          // }
          this.activitiListing = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
    if (window.innerWidth < 800) {
      this.mobileView = true
    } else {
      this.mobileView = false
    }
  },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
</style>  